



























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Field from '@/components/Field.vue';
import { Id } from '../../user/domain/Types';
import Card from '@/components/card/Card.vue';
import { AdvertiserService } from '../../advertiser/services/AdvertiserService';
import { Advertiser } from '../../advertiser/domain/Advertiser';
import { Campaign } from '../domain/Campaign';
import { Portal } from '@/modules/portal/domain/Portal';

@Component({
    components: { Field, Card },
})
export default class CampaignInformationCard extends Vue {
    @Prop() private readonly givenCampaign!: Campaign;
    @Prop() private readonly hasSubmitted!: boolean;

    private advertiserId: Id | undefined = '';
    private advertisers: Advertiser[] = [];
    private isLoading: boolean = false;
    private givenPortal: Portal | null = null;
    private portal: Portal | null = null;

    private async mounted() {
        this.isLoading = true;
        try {
            this.advertisers = await AdvertiserService.getAllAdvertisers(
                this.$store.getters['sessionStore/token'],
            );
        } catch {
            this.$notify.error({
                title: 'Erreur',
                message:
                    'Erreur lors du chargement de vos annonceurs. Veuillez recharger la page!',
            });
        }
        this.isLoading = false;
    }

    private findAdvertiserBySelectedId() {
        return this.advertisers.find(
            (advertiser) => advertiser.id === this.advertiserId,
        );
    }

    @Watch('givenCampaign')
    private onGivenCampaignUpdate() {
      const advertiser = this.givenCampaign.advertiser;
      if (advertiser) {
        this.advertiserId = advertiser.id;
      }
    }

    @Watch('advertiserId')
    private onAdvertiserIdUpdate() {
      const advertiser = this.findAdvertiserBySelectedId();
      if (advertiser) {
          this.givenCampaign.advertiser = advertiser;
      }
    }

    private async onPortalSelected(portal: Portal) {
      if (portal) {
        this.portal = portal;
        this.$emit('updatePortal', this.portal);
      }
    }
}
