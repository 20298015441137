


















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Card from '@/components/card/Card.vue';
import Field from '@/components/Field.vue';
import { Campaign } from '../domain/Campaign';

@Component({
    components: { Card, Field },
})
export default class CampaignStatsCard extends Vue {
    @Prop() private readonly givenCampaign!: Campaign;
}
